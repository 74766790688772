<template>
	<div v-loading="loading">
		<el-collapse class="PatientInformation">
			<el-collapse-item>
				<template slot="title">
					<el-row :gutter="20" class="usertitle" v-if="cgmTableData.user">
						<el-col :span="5" v-if="cgmTableData.user.name">
							<div class="grid-content bg-purple">姓名：{{ cgmTableData.user.name }}</div>
						</el-col>
						<el-col :span="5" v-if="!cgmTableData.user.name">
							<div class="grid-content bg-purple">用户名：{{ cgmTableData.user.account }}</div>
						</el-col>
						<el-col :span="5">
							<div class="grid-content bg-purple">电话：{{ cgmTableData.user.mobile }}</div>
						</el-col>
						<el-col :span="4">
							<div class="grid-content bg-purple">性别：{{ cgmTableData.user.gender === 1 ? '男' : cgmTableData.user.gender === 0 ? '女' : '-' }}</div>
						</el-col>
						<el-col :span="5">
							<div class="grid-content bg-purple" v-if="cgmTableData.user.birthday">年龄：{{ cgmTableData.user.birthday | toAge }}</div>
							<div class="grid-content bg-purple" v-if="!cgmTableData.user.birthday">年龄：-</div>
						</el-col>
						<el-col :span="5">
							<div class="grid-content bg-purple">邮箱：{{ cgmTableData.user.email == '' || cgmTableData.user.email == null ? '-' : cgmTableData.user.email }}</div>
						</el-col>
					</el-row>
				</template>
				<el-row :gutter="20" class="usertitle" v-if="cgmTableData.user">
					<el-col :span="5">
						<div class="grid-content bg-purple">身高：{{ cgmTableData.height === null ? '-' : cgmTableData.height + 'cm' }}</div>
					</el-col>
					<el-col :span="5">
						<div class="grid-content bg-purple">体重：{{ cgmTableData.weight === null ? '-' : cgmTableData.weight + 'kg' }}</div>
					</el-col>
					<el-col :span="4">
						<div class="grid-content bg-purple">BMI：{{ cgmTableData.bmi == null || cgmTableData.bmi == '' ? '-' : cgmTableData.bmi }}</div>
					</el-col>
					<el-col :span="5">
						<div class="grid-content bg-purple">
							糖尿病类型：{{
								cgmTableData.glycuresisType === 1
									? 'I型糖尿病'
									: cgmTableData.glycuresisType === 2
									? 'II型糖尿病'
									: cgmTableData.glycuresisType === 3
									? '妊娠糖尿病'
									: cgmTableData.glycuresisType === 4
									? '其他'
									: '-'
							}}
						</div>
					</el-col>
					<el-col :span="5">
						<div class="grid-content bg-purple">诊断年限：{{ cgmTableData.diagnosticYear }}</div>
					</el-col>
				</el-row>
				<el-row :gutter="20" class="usertitle" v-if="cgmTableData.user">
					<el-col :span="5">
						<div class="grid-content bg-purple">是否使用胰岛素：{{ cgmTableData.useInsulin === 1 ? '是' : cgmTableData.useInsulin === 0 ? '否' : '暂未设置' }}</div>
					</el-col>
					<el-col :span="5">
						<div class="grid-content bg-purple">紧急联系人手机：{{ cgmTableData.user.contactMobile == null ? '-' : cgmTableData.user.contactMobile }}</div>
					</el-col>
				</el-row>
			</el-collapse-item>
		</el-collapse>
		<div class="CgmGuardianRtwarp">
			<CgmGuardianRt :puid="puid"></CgmGuardianRt>
			<div v-if="alarmData.length == 0 ? false : true">
				<div class="alarm-main-warp" :style="{ height: alarmShow == true ? '140px' : 'auto' }">
					<div @click="alarmNav" class="alarm-show" :class="alarmShow == true ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></div>
					<div class="alarm-warp" :key="index" v-for="(item, index) in alarmData">
						<div class="alarm-box" :class="item.level === 3 ? 'alarmColorRed' : item.level === 2 ? 'alarmColorYellow' : item.level === 1 ? 'alarmColorGreen' : ''">
							<em></em>
							{{ item.createTime | splitTime }}&nbsp;&nbsp;{{ item.content }}
						</div>
					</div>
				</div>
			</div>
			<div class="DeviceInfo" v-if="findByDevice.length !== 0 ? true : false">
				<el-divider content-position="center">发射器设备信息</el-divider>
				<el-row :gutter="20" class="usertitle">
					<el-col :span="6">
						<div class="grid-content bg-purple">序列号：{{ findByFsq.serialNumber }}</div>
					</el-col>
					<el-col :span="6">
						<div class="grid-content bg-purple">软件版本：{{ findByFsq.softVersion }}</div>
					</el-col>
					<el-col :span="6">
						<div class="grid-content bg-purple">硬件版本：{{ findByFsq.hardVersion }}</div>
					</el-col>
					<el-col :span="6">
						<div class="grid-content bg-purple">激活时间：{{ findByFsq.activeTime }}</div>
					</el-col>
				</el-row>
			</div>
			<div class="DeviceInfo" v-if="findByDevice.length !== 0 ? true : false">
				<el-divider content-position="center">传感器设备信息</el-divider>
				<el-row :gutter="20" class="usertitle">
					<el-col :span="6">
						<div class="grid-content bg-purple">序列号：{{ findByCgq.serialNumber }}</div>
					</el-col>
					<el-col :span="6">
						<div class="grid-content bg-purple">激活时间：{{ findByCgq.activeTime }}</div>
					</el-col>
				</el-row>
			</div>
			<div class="DeviceInfo">
				<el-divider content-position="center">报警设置</el-divider>
				<el-row :gutter="20" class="usertitle">
					<el-col :span="6">
						<div class="grid-content bg-purple">高血糖阈值：{{ cgmTableData.highThresholdMmol }} mmol/L</div>
					</el-col>
					<el-col :span="6">
						<div class="grid-content bg-purple">低血糖阈值：{{ cgmTableData.lowThresholdMmol }} mmol/L</div>
					</el-col>
					<el-col :span="6">
						<div class="grid-content bg-purple">
							紧急低血糖阈值：
							<span style="font-weight: bold;">3.1</span>
							mmol/L
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="cgmSensorInfo">
				<el-table style="width: 100%" size="medium" stripe border :data="cgmSensorTransmitterPairData">
					<el-table-column label="编号" type="index" width="100" align="center"></el-table-column>
					<el-table-column label="发射器序列号 " prop="transmitterDeviceSerialNumber"></el-table-column>
					<el-table-column label="传感器序列号" prop="sensorDeviceSerialNumber"></el-table-column>
					<el-table-column label="绑定时间" prop="createTime"></el-table-column>
				</el-table>
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="cgmSensorPage"
					:page-sizes="[10, 20, 50, 100]"
					:page-size="cgmSensorSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total"
				></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import CgmGuardianRt from './CgmGuardianRt.vue';
import { calDate } from '../utils/convert.js';

export default {
	name: 'CgmLive',
	components: {
		CgmGuardianRt
	},
	data() {
		return {
			loading: true,
			cgmTableData: [],
			cgmDeviceList: [],
			searchTime: null,
			alarmData: [],
			findByCgq: {},
			findByFsq: {},
			findByDevice: [],
			timer: null, //定时器名称
			cgmSensorTransmitterPairData: [],
			alarmShow: true,
			cgmSensorPage: 1,
			cgmSensorSize: 10,
			totalPages:null,
			total:null
		};
	},
	props: {
		puid: String
	},
	created() {
		this.cgmPatientDetail();
		this.getAlarmDetail();
		this.cgmfFindByCgq();
		this.getCgmSensorTransmitterPair();
	},
	mounted() {
		//定时器
		this.timer = setInterval(() => {
			//setTimeout(this.getOneDayEvent,0)
			this.getAlarmDetail();
		}, 60000);
	},
	methods: {
		//报警列表up down
		alarmNav() {
			this.alarmShow = !this.alarmShow;
		},
		handleSizeChange(val) {
			this.cgmSensorSize = val;
			this.getCgmSensorTransmitterPair();
		},
		handleCurrentChange(val) {
			this.cgmSensorPage = val;
			this.getCgmSensorTransmitterPair();
		},
		//传感器与接收器配对列表
		getCgmSensorTransmitterPair() {
			var params = {
				userUuid: this.puid,
				page:this.cgmSensorPage,
				size:this.cgmSensorSize
			};
			console.log(params)
			this.$axios.post('/api/cgm/web/sensorTransmitterPair/page', params).then(response => {
				console.log(response);
				if (response.data.code === 200) {
					this.totalPages = response.data.pageData.totalPages;
					this.total = response.data.pageData.totalElements;
					this.cgmSensorTransmitterPairData = response.data.data;
					console.log('1234567890', this.cgmSensorTransmitterPairData);
				} else {
					this.$message({
						message: '传感器与接收器配对操作失败',
						type: 'error'
					});
				}
			});
		},
		//报警记录
		getAlarmDetail() {
			const nowDate = new Date();
			const date = {
				year: nowDate.getFullYear(),
				month: nowDate.getMonth() + 1,
				date: nowDate.getDate()
			};
			const newmonth = date.month > 9 ? date.month : '0' + date.month;
			const day = date.date > 9 ? date.date : '0' + date.date;
			this.searchTime = date.year + '-' + newmonth + '-' + day;
			var params = {
				userUuid: this.puid,
				endCreateTime: this.searchTime + ' 23:59:59',
				startCreateTime: this.searchTime + ' 00:00:00'
			};
			console.log(params);
			this.$axios.post('/api/cgm/web/patientWarningLog/list', params).then(response => {
				console.log(response.data.data);
				if (response.data.code === 200) {
					if (response.data.data.length !== 0) {
						if(response.data.data[0].logList!=null){
							this.alarmData = response.data.data[0].logList.sort(function(a,b){
								return a["createTime"]<b["createTime"]?-1:(a["createTime"]>b["createTime"]?1:0);
							});
						}else{
							this.alarmData=[];
						}
					}
				} else {
					this.$message({
						message: '报警记录操作失败',
						type: 'error'
					});
				}
			});
		},
		//患者详细
		cgmPatientDetail() {
			this.$axios
				.get('/api/sys/web/patient/detail/' + this.puid)
				.then(response => {
					if (response.data.code === 200) {
						this.cgmTableData = response.data.data;		
						if(this.cgmTableData.user.mobile){
							this.cgmTableData.user.mobile = this.cgmTableData.user.mobile.substring(0,3) + "****" 
								+ this.cgmTableData.user.mobile.substring(7,12);
							}
						if(this.cgmTableData.user.contactMobile){
							this.cgmTableData.user.contactMobile = this.cgmTableData.user.contactMobile.substring(0,3) + "****" 
								+ this.cgmTableData.user.contactMobile.substring(7,12);
							}
						if(this.cgmTableData.user.name){
							var name = this.cgmTableData.user.name;
							if(name.length>2){
								name = name.substring(0,1) + "***" + name.substring(name.length-1,name.length);
							}else{
								name = name.substring(0,1) + "*";
							}
							this.cgmTableData.user.name = name;
						}
						if(this.cgmTableData.user.email){
							var email = this.cgmTableData.user.email;
							var index = email.indexOf("@");
							if(index>4){
								email = email.substring(0,2) + "******" + email.substring(index, email.length);
							}else{
								email = email.substring(0,1) + "******" + email.substring(index, email.length);
							}
							this.cgmTableData.user.email=email;
						}
										
						this.cgmTableData.diagnosticYear = calDate(this.cgmTableData.diagnosticYear) + '年';
						let cgmDeviceList = response.data.data.user.deviceList;
						let vm = this;
						cgmDeviceList.forEach(function(item1) {
							if (item1.isConnect === 1) {
								vm.cgmDeviceList.push(item1);
							}
						});
						this.loading = false;
					}
				})
				.catch(e => {
					console.log(e);
					/* this.$message({
						message: '患者详细请求失败',
						type: 'error'
					}); */
				});
		},
		//传感器
		cgmfFindByCgq() {
			console.log(this.puid);
			this.$axios
				.get('/api/cgm/web/patientDevice/findByDevice/' + this.puid)
				.then(response => {
					if (response.data.code === 200) {						
						this.findByDevice = response.data.data;
						console.log("传感器和发射器");
						console.log(this.findByDevice);
						for (var i = 0; i < this.findByDevice.length; i++) {
							if (this.findByDevice[i].type === 1) {
								this.findByFsq = this.findByDevice[i];
							} else if (this.findByDevice[i].type === 2) {
								this.findByCgq = this.findByDevice[i];
							}
						}
						console.log(this.findByFsq);
					} else {
						this.$message({
							message: '传感器请求失败',
							type: 'error'
						});
					}
				})
				.catch(e => {
					console.log(e);
					/* this.$message({
						message: '传感器请求失败1',
						type: 'error'
					}); */
				});
		}
	},
	beforeDestroy() {
		clearInterval(this.timer);
		this.timer = null;
	}
};
</script>
<style scoped>
.cgmSensorInfo {
	padding: 0 20px;
	margin-top: 30px;
}
.alarm-main-warp {
	position: relative;
	height: 140px;
	overflow: hidden;
	text-align: left;
	padding: 0 30px;
	z-index: 999;
}
.alarm-show {
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 40px;
	position: absolute;
	font-size: 22px;
	right: 0;
	top: 0;
	cursor: pointer;
}
.alarm-warp {
	height: 60px;
	border-radius: 8px;
	overflow: hidden;
	border: 1px solid #e2e2e2;
	display: inline-block;
	margin: 0 10px 10px 0;
}
.alarm-box {
	padding: 0 20px 0 20px;
	line-height: 60px;
}
.alarm-box em {
	width: 20px;
	height: 20px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 6px;
	margin-top: -2px;
	background-size: 80%;
	background-repeat: no-repeat;
}
.alarm-box.alarmColorRed {
	border-top: 5px solid #f33131;
}
.alarm-box.alarmColorRed em {
	background-image: url(../assets/images/alarm1.png);
}
.alarm-box.alarmColorYellow {
	border-top: 5px solid #fdae61;
}
.alarm-box.alarmColorYellow em {
	background-image: url(../assets/images/alarm2.png);
}
.alarm-box.alarmColorGreen {
	border-top: 3px solid #46d4c4;
}
.alarm-box.alarmColorGreen em {
	background-image: url(../assets/images/alarm3.png);
}
/deep/.PatientInformation .is-active .el-collapse-item__header {
	margin-top: 10px;
}
/deep/.PatientInformation .is-active .el-collapse-item__header,
/deep/.PatientInformation .is-active .el-collapse-item__content {
	height: 40px;
	line-height: 40px;
}
.ListMain {
	padding: 10px;
}
.graycolor {
	color: #bbb;
}
.DeviceInfo {
	padding: 0 20px;
}
.CgmGuardianRtwarp {
	width: 100%;
	background-color: #fff;
	/*height: 550px;*/
	border-radius: 10px;
	overflow: hidden;
	margin: 10px 0;
	padding-bottom: 20px;
	padding-top: 20px;
}
/deep/.usertitle {
	width: 100%;
	margin: 0;
	font-size: 14px;
	text-align: left;
}
.PatientInformation {
	min-height: 60px;
	border-radius: 10px;
	background-color: #fff;
	overflow: hidden;
}
/deep/.usertitle .grid-content {
	white-space: nowrap;
	overflow: hidden;
}
</style>

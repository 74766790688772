function appendZero(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}

export function parseTime(input) {
    if (!input) {
        return "";
    }
    const date = new Date(input);
    return `${date.getFullYear()}-${appendZero(date.getMonth() + 1)}-${appendZero(
        date.getDate()
    )} ${appendZero(date.getHours())}:${appendZero(date.getMinutes())}`;
}


export function parseDate(d) {
    return `${d.getFullYear()}-${d.getMonth() + 1 >= 10 ? (d.getMonth() + 1) : '0' + (d.getMonth() + 1)}-${d.getDate() >= 10 ? d.getDate() : '0' + d.getDate()}`;
}

export function calDate(value) {
    if (!value) {
        return "-";
    }
    //出生时间 毫秒
    const birthDayTime = new Date(value).getTime();
    //当前时间 毫秒
    const nowTime = new Date().getTime();
    //一年毫秒数(365 * 86400000 = 31536000000)
    return Math.ceil((nowTime - birthDayTime) / 31536000000);
}

import eventFood from "../assets/images/icon_event_food.png";
import eventBg from "../assets/images/icon_event_bg.png";
import eventExercise from "../assets/images/icon_event_exercise.png";
import eventOther from "../assets/images/icon_event_other.png";
import eventMedicine from "../assets/images/icon_event_medicine.png";
import eventInsulin from "../assets/images/icon_event_insulin.png";
import eventSleep from "../assets/images/icon_event_sleep.png";
import {
	xData
} from "../utils/chartXData.js"

export default {
	name: "CgmGuardianRt",
	data() {
		return {
			chartInstance: null,
			chartInstances: null,
			xAxisData: [],
			xAxisDataAll: [],
			yAxisData: [],
			yDietEvent: [],
			realtime: '',
			oneDayData: [],
			allMinutes: [],
			OneDayEvent: [],
			OneDay: [],
			timer: null, //定时器名称
			loading: true,
		}
	},
	props: {
		puid: String
	},
	mounted() {
		this.getxAxisData();
		this.getFindByOneDay();
		//定时器
		this.timer = setInterval(() => {
			//setTimeout(this.getOneDayEvent,0)
			this.getFindByOneDay()
		}, 60000)
	},
	methods: {
		//x轴整体数据
		getxAxisDataAll() {
			var xAxisData = [];
			var xMinute = [];
			var xHour = [];
			for (var min = 0; min < 60; min++) {
				var setMin = min + '';
				if (setMin < 10) {
					setMin = '0' + setMin
				}
				xMinute.push(setMin)
			}
			for (var hour = 0; hour < 24; hour++) {
				var setHour = hour + '';
				if (setHour < 10) {
					setHour = '0' + setHour
				}
				xHour.push(setHour);
			}
			//var setxHour = xHour
			//xHour = xHour.concat(setxHour)
			for (var h = 0; h < 24; h++) {
				for (var s = 0; s < 60; s++) {
					var setTime = xHour[h] + ":" + xMinute[s]
					xAxisData.push(setTime);
				}
			}
			this.xAxisDataAll = xAxisData;
		},
		getxAxisData() {
			let newDate = new Date();
			let yy = newDate.getFullYear();
			let mm = newDate.getMonth() + 1;
			mm = mm < 10 ? '0' + mm : mm;
			let dd = newDate.getDate();
			dd = dd < 10 ? '0' + dd : dd;
			let forCal = new Date(yy + '-' + mm + '-' + dd + ' 00:00:00');
			let t_s = forCal.getTime();
			for (let i = 0; i < 1440; i++) {
				forCal.setTime(t_s + 1000 * 60 * i);
				let newHour = forCal.getHours() < 10 ? '0' + forCal.getHours() : forCal.getHours();
				let newMin = forCal.getMinutes() < 10 ? '0' + forCal.getMinutes() : forCal.getMinutes();
				let newtime = yy + '-' + mm + '-' + dd + ' ' + newHour + ':' + newMin + ':00';
				this.allMinutes.push(newtime);
			}
		},
		//CGM24小时血糖数据
		getFindByOneDay() {
			console.log(this.puid)
			this.$axios.get('/api/cgm/web/patientCgmData/findByOneDay/' + this.puid)
				.then(res => {
					this.OneDay = res.data.data;
					let arr = [];
					this.allMinutes.forEach(iii => {
						let isHas = false;
						this.OneDay.forEach(item => {
							if (iii === item.measureTime) {
								arr.push(item.glucose);
								isHas = true;
							}
						});
						if (!isHas) {
							arr.push('N/A');
						}
					});
					this.oneDayData = arr;
					this.getxAxisData();
					this.getOneDayEvent();
					//this.getyAxisData();
					this.initchart();
					this.loading = false;
				}).catch(e => {
					console.log(e);
					/* this.$message({
						message: "请求失败2",
						type: "error"
					}); */
				});
			console.log(this.loading)
		},
		//CGM 24小时事件
		getOneDayEvent() {
			var params = {
				userUuid: this.puid
			}
			console.log(params)
			this.$axios.post('/api/cgm/web/event/getOneDay', params).then(response => {
				// post 成功，response.data 为返回的数据
				if (response.data.code === 200) {
					this.OneDayEvent = response.data.data;
					var vm = this;
					var xAxisData = this.allMinutes;
					var yDietEvent = this.yDietEvent;
					if (this.OneDayEvent !== null) {
						for (var Dietnum = 0; Dietnum < 1440; Dietnum++) {
							yDietEvent[Dietnum] = {
								value: 0,
								symbol: null,
							};
						}
						this.OneDayEvent.forEach(function(item2) {
							var startTime = item2.time.substring(0, 17) + '00'
							for (var setDietnum = 0; setDietnum < 1440; setDietnum++) {
								if (startTime == xAxisData[setDietnum]) {
									if (item2.type == 1) {
										yDietEvent[setDietnum].symbol = `image://${eventBg}`;
										yDietEvent[setDietnum].value = 1;
									} else if (item2.type == 2) {
										yDietEvent[setDietnum].symbol = `image://${eventFood}`;
										yDietEvent[setDietnum].value = 1;
									} else if (item2.type == 3) {
										yDietEvent[setDietnum].symbol = `image://${eventExercise}`;
										yDietEvent[setDietnum].value = 1;
									} else if (item2.type == 4) {
										yDietEvent[setDietnum].symbol = `image://${eventInsulin}`;
										yDietEvent[setDietnum].value = 1;
									} else if (item2.type == 5) {
										yDietEvent[setDietnum].symbol = `image://${eventMedicine}`;
										yDietEvent[setDietnum].value = 1;
									} else if (item2.type == 6) {
										yDietEvent[setDietnum].symbol = `image://${eventOther}`;
										yDietEvent[setDietnum].value = 1;
									} else if (item2.type == 7) {
										yDietEvent[setDietnum].symbol = `image://${eventSleep}`;
										yDietEvent[setDietnum].value = 1;
									}
								}
							}
							vm.yDietEvent = yDietEvent;
						});
						this.initchartEvent();
					}
				}
			}).catch(error => {
				// 请求失败
				console.log(error)
				/* this.$message({
					message: "请求失败3",
					type: "error"
				}); */
			})
		},
		//初始化echartInstance对象
		initchart() {
			this.chartInstance = this.$echarts.init(this.$refs.seller_ref);
			const initOption = {
				tooltip: {
					trigger: 'axis',
					formatter(params){
						var startTime = new Date();
						startTime.setHours(0);
						startTime.setMinutes(0);
						startTime.setMinutes(startTime.getMinutes() + params[0].dataIndex);     
						var hour=  startTime.getHours();
						var mins = startTime.getMinutes();
						if(hour<10)
							hour="0"+hour;
						if(mins<10)
							mins="0" + mins;
						var valueStr = params[0].value;
						if(valueStr==null || valueStr==undefined || valueStr=="N/A")
							return null;
						else{
							valueStr = params[0].value.toFixed(1);
							return valueStr + " mmol/L" + "<br/>" + hour+":"+mins;
						}
					}
				},
				grid: {
					top: 80,
					left: 20,
					right: 40,
					containLabel: true
				},
				title: {
					text: "今天的血糖变化",
					left: "center",
					top: 20,
					textStyle: {
						fontSize: 18,
						fontWeight: "bold",
					},
				},
				xAxis: {
					type: 'category',
					data: xData,
					boundaryGap: false,
					axisLabel: {
						interval: 0
					},
					axisTick: {
						interval: 59
					}
				},
				yAxis: {
					type: 'value',
					//y轴最大值最小值
					min: 2,
					max: 22,
					scale: true,
				},
				//线的颜色样式
				visualMap: {
					top: 500,
					type: 'piecewise',
					//symbolSize: 80,
					pieces: [{
						gt: 2,
						lte: 3.9,
						color: '#f33131',
					}, {
						gt: 3.9,
						lte: 10,
						color: '#494949',

					}, {
						gt: 10,
						lte: 25,
						color: '#fdae61',
					}],
				},
				series: [{
					name: '',
					color: '#22c9e4',
					top: 0,
					data: this.oneDayData,
					//线形
					type: 'line',
					//平滑的曲线
					smooth: true,
					//是否显示节点的○符号
					showSymbol: false,
					lineStyle: {
						width: 2
					},
					markArea: { //标记区域
						data: [
							[{
								yAxis: '3.9',
								itemStyle: {
									color: '#4870FF23'
								}
							}, {
								yAxis: '10'
							}]
						]
					},
					//分割线
					/* markLine: {
						symbolSize: [0, 0],
						silent: true,
						data: [{
							yAxis: 3.9,
							lineStyle: {
								color: '#f33131',
								width: 1.5,
								type: 'solid'
							},
						}, {
							yAxis: 10,
							lineStyle: {
								color: '#f1b907',
								width: 1.5,
								type: 'solid'
							},
						}]
					}, */
				}],
			};
			this.chartInstance.setOption(initOption)
		},
		initchartEvent() {
			this.chartInstances = this.$echarts.init(this.$refs.seller_refa);
			const initOption = {
				grid: {
					top: 0,
					left: 20,
					right: 40,
					containLabel: true
				},
				xAxis: {
					type: 'category',
					data: xData,
					boundaryGap: false,
					show: false,
					axisLabel: {
						interval: 0
					},
					axisTick: {
						interval: 59
					}
				},
				yAxis: {
					type: 'value',
					//y轴最大值最小值
					min: 0,
					max: 1,
					scale: true,
					show: false
				},
				series: [{
					name: '事件',
					type: 'pictorialBar',
					//图片大小
					symbolSize: [36, 68],
					smooth: true,
					data: this.yDietEvent,
				}],
			};
			this.chartInstances.setOption(initOption)
		},
	},
	beforeDestroy() {
		clearInterval(this.timer);
		this.timer = null;
	}
}

export const xData = [
    "00:00","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "03:00","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "06:00","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "09:00","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "12:00","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "15:00","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "18:00","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "21:00","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    "24:00"
];
export const yData = [
	{time:'08:01',glucose:'2.1'},{time:'08:02',glucose:'2.2'},{time:'08:03',glucose:'2.4'},{time:'08:04',glucose:'2.6'},{time:'08:05',glucose:'2.8'},
	{time:'08:06',glucose:'2.9'},{time:'08:07',glucose:'3.0'},{time:'08:08',glucose:'3.2'},{time:'08:09',glucose:'3.4'},{time:'08:10',glucose:'3.6'},
	{time:'08:11',glucose:'3.8'},{time:'08:12',glucose:'4.1'},{time:'08:13',glucose:'4.1'},{time:'08:14',glucose:'4.4'},{time:'08:15',glucose:'4.8'},
	{time:'08:16',glucose:'5.2'},{time:'08:17',glucose:'5.6'},{time:'08:18',glucose:'5.9'},{time:'08:19',glucose:'6.2'},{time:'08:20',glucose:'6.7'},
	{time:'08:21',glucose:'7.4'},{time:'08:22',glucose:'7.9'},{time:'08:23',glucose:'8.5'},{time:'08:24',glucose:'9.3'},{time:'08:25',glucose:'9.9'},
	{time:'08:26',glucose:'10.4'},{time:'08:27',glucose:'10.9'},{time:'08:28',glucose:'11.4'},{time:'08:29',glucose:'11.9'},{time:'08:30',glucose:'12.2'},
	{time:'08:31',glucose:'12.8'},{time:'08:32',glucose:'12.5'},{time:'08:33',glucose:'12.0'},{time:'08:34',glucose:'11.6'},{time:'08:35',glucose:'11.1'},
	{time:'08:36',glucose:'10.7'},{time:'08:37',glucose:'10.3'},{time:'08:38',glucose:'9.9'},{time:'08:39',glucose:'9.4'},{time:'08:40',glucose:'9.0'},
	{time:'08:41',glucose:'8.6'},{time:'08:42',glucose:'8.2'},{time:'08:43',glucose:'7.8'},{time:'08:44',glucose:'7.2'},{time:'08:45',glucose:'6.8'},
	{time:'08:46',glucose:'6.5'},{time:'08:47',glucose:'6.0'},{time:'08:48',glucose:'5.6'},{time:'08:49',glucose:'6.2'},{time:'08:50',glucose:'6.7'},
	{time:'08:51',glucose:'7.1'},{time:'08:52',glucose:'7.7'},{time:'08:53',glucose:'7.9'},{time:'08:54',glucose:'8.3'},{time:'08:55',glucose:'8.8'},
]
